.TextareaViewLabel {
  display: block;
  font-family: SF Pro Display, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.025em;
}
.TextareaViewInput {
  display: block;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  outline: none;
  border: 1px solid #d1d2d7;
  padding: 3px 7px 5px;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0;
  margin: 0;
}
.TextareaViewLabel + .TextareaViewInput {
  margin: 12px 0 0;
}
.TextareaView--title .TextareaViewInput {
  font-size: 36px;
  line-height: 50px;
  letter-spacing: -0.05em;
  padding: 2px 18px 2px;
}
.TextareaView--form .TextareaViewInput {
  padding: 3px 18px 5px;
}
.TextareaView--form_company .TextareaViewInput {
  padding: 3px 13px 5px;
}
.TextareaView--name .TextareaViewInput {
  font: 600 46px/50px SF Pro Display, sans-serif;
  letter-spacing: -0.05em;
  padding: 23px 17px;
}
.TextareaView--caption .TextareaViewInput {
  font: 12px/15px SF Pro Display, sans-serif;
  letter-spacing: 0;
  padding: 7px 8px 5px;
}
.u-ShowErrors .TextareaView.u-ErrorView .TextareaViewInput {
  border-color: red;
}
