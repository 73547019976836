.ColorPicker {
  position: relative;
}
.ColorPickerLabel {
  display: block;
  font-family: SF Pro Display, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.025em;
}
.ColorPickerPreview {
  display: block;
  border: 1px solid #d1d2d7;
  cursor: pointer;
}
.ColorPickerLabel + .ColorPickerInput {
  margin: 12px 0 0;
}
.ColorPickerEditor {
  position: absolute !important;
  top: 100%;
  left: 0;
  z-index: 2;
}
.u-ShowErrors .ColorPicker.u-ErrorView .ColorPickerInput {
  border-color: red;
}
.ColorPickerInput--transparentCaret {
  caret-color: transparent;
}
