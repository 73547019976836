.SelectView[data-v-1434e894] {
  position: relative;
}
.SelectViewLabel[data-v-1434e894] {
  display: block;
  font-family: SF Pro Display, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.025em;
}
.SelectViewInput[data-v-1434e894] {
  /*
            /deep/ .selected-tag {
                max-width: calc(100% - 20px);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                //display: inline-block;
            }
    */
}
.SelectViewInput[data-v-1434e894] .clear {
  display: none !important;
}
.SelectViewInput[data-v-1434e894] .dropdown-toggle {
  border: 1px solid #d1d2d7;
  border-radius: 0;
  padding: 1px 4px 7px;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0;
  font-family: SF Pro Display, sans-serif;
}
.SelectViewInput[data-v-1434e894] .dropdown-menu {
  overflow: auto !important;
  padding: 0;
  border-radius: 0;
}
.SelectViewInput[data-v-1434e894] .dropdown-menu li[role=option] {
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0;
  font-family: SF Pro Display, sans-serif;
}
.SelectViewInput[data-v-1434e894] .dropdown-menu li[role=option] a {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.SelectViewInput[data-v-1434e894] .open-indicator:before {
  width: 0;
  height: 0;
  border: 4px solid;
  border-color: black transparent transparent transparent;
  position: relative;
  top: -2px;
  -webkit-transform: none !important;
      -ms-transform: none !important;
          transform: none !important;
}
.SelectViewInput[data-v-1434e894] .open-indicator {
  display: inline-block;
  padding: 0;
  margin: 14px 5px 0 0;
  width: 8px;
  height: 8px;
}
.SelectViewInput.open[data-v-1434e894] .open-indicator {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.SelectViewInput[data-v-1434e894] .no-options {
  font-weight: normal;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 0;
  font-family: SF Pro Display, sans-serif;
  color: #48555d;
}
.SelectViewInput[data-v-1434e894] .selected-tag {
  max-width: calc(100% - 20px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}
.SelectViewInput[data-v-1434e894] .vs__selected-options {
  max-width: calc(100% - 22px);
}
.SelectViewLabel + .SelectViewInput[data-v-1434e894] {
  margin: 12px 0 0;
}
.SelectViewEdit[data-v-1434e894] {
  position: absolute;
  top: 3px;
  right: 0;
  font: 12px/30px SF Pro Display, sans-serif;
  color: #00aada;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  cursor: pointer;
}
.SelectViewEdit[data-v-1434e894]:hover {
  opacity: 0.7;
}
.SelectViewOption[data-v-1434e894] {
  pointer-events: none;
}
.u-ShowErrors .SelectView.u-ErrorView .SelectViewInput[data-v-1434e894] .dropdown-toggle {
  border-color: red !important;
}
