.MenuIconButton[data-v-2a11630b] {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  background: var(--button-bg-color);
  -webkit-backdrop-filter: blur(27.1828px);
          backdrop-filter: blur(27.1828px);
  border-radius: 50%;
  -webkit-transition: 0.2s background;
  transition: 0.2s background;
  cursor: pointer;
}
.MenuIconButton[data-v-2a11630b]:hover {
  background: var(--button-bg-hover-color);
}
.MenuIconButton:hover svg[data-v-2a11630b] {
  fill: var(--button-icon-hover-color);
}
.MenuIconButton svg[data-v-2a11630b] {
  position: absolute;
  top: 14px;
  left: 9px;
  width: 15px;
  height: 4px;
  fill: var(--button-icon-color);
  -webkit-transition: 0.2s fill;
  transition: 0.2s fill;
}
