.PopupView[data-v-4f640124] {
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 100px 0;
  background: rgba(0, 0, 0, 0.3);
  visibility: hidden;
}
.PopupView.show[data-v-4f640124] {
  visibility: visible;
}
.PopupViewContent[data-v-4f640124] {
  background: white;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.14);
          box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.14);
  padding: 0 158px 100px 160px;
}
.PopupViewClose[data-v-4f640124] {
  position: absolute;
  top: 0;
  right: 0;
  padding: 23px;
  cursor: pointer;
}
.PopupViewClose svg[data-v-4f640124] {
  width: 31px;
  height: 31px;
  -webkit-transition: fill 0.2s;
  transition: fill 0.2s;
}
.PopupViewClose:hover svg[data-v-4f640124] {
  fill: #00aada;
}
.PopupViewTitle[data-v-4f640124] {
  font: 600 36px/40px SF Pro Display, sans-serif;
  padding: 90px 0 67px;
  text-align: center;
  letter-spacing: -0.05em;
  text-transform: uppercase;
}
.PopupView--small .PopupViewContent[data-v-4f640124] {
  padding: 0 99px 120px 99px;
}
.PopupView--small .PopupViewTitle[data-v-4f640124] {
  font: 700 18px/30px SF Pro Display, sans-serif;
  padding: 19px 0 47px;
  letter-spacing: -0.025em;
  text-transform: none;
}
.PopupView--small2 .PopupViewContent[data-v-4f640124] {
  padding: 0 160px 120px 160px;
}
.PopupView--small2 .PopupViewTitle[data-v-4f640124] {
  font: 700 18px/30px SF Pro Display, sans-serif;
  padding: 24px 0 44px;
  letter-spacing: -0.025em;
  text-transform: none;
}
