.DeleteIconButton[data-v-46f0cfd2] {
  position: relative;
  display: block;
  width: 56px;
  height: 56px;
  background: var(--button-bg-color);
  -webkit-backdrop-filter: blur(27.1828px);
          backdrop-filter: blur(27.1828px);
  border-radius: 50%;
  -webkit-transition: 0.2s background;
  transition: 0.2s background;
  cursor: pointer;
}
.DeleteIconButton[data-v-46f0cfd2]:hover {
  background: var(--button-bg-hover-color);
}
.DeleteIconButton:hover svg[data-v-46f0cfd2] {
  fill: var(--button-icon-hover-color);
}
.DeleteIconButton svg[data-v-46f0cfd2] {
  position: absolute;
  top: 14px;
  left: 17px;
  width: 22px;
  height: 27px;
  fill: var(--button-icon-color);
  -webkit-transition: 0.2s fill;
  transition: 0.2s fill;
}
