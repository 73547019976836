.EyeButton[data-v-2d6c1a92] {
  font-size: 0;
  position: relative;
}
.EyeButton svg[data-v-2d6c1a92] {
  position: absolute;
  top: 0;
  left: 0;
}
.EyeButton .svg--eyeopen[data-v-2d6c1a92] {
  width: 100%;
  height: 100%;
}
.EyeButton .svg--eyeshut[data-v-2d6c1a92] {
  width: 100%;
  height: 70%;
  top: 30%;
}
.EyeButton.disabled[data-v-2d6c1a92] {
  pointer-events: none;
}
.EyeButton.disabled svg[data-v-2d6c1a92] {
  fill: #B2B2B2;
}
