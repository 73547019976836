.ImageUploadView.size-small[data-v-07e8b23e] {
  width: 125px;
}
.ImageUploadView.size-medium[data-v-07e8b23e] {
  width: 192px;
}
.ImageUploadView.size-big[data-v-07e8b23e] {
  width: 304px;
}
.ImageUploadViewInner[data-v-07e8b23e] {
  position: relative;
  border-radius: 14px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-box-shadow: 0px 2px 8px var(--small-outline-shadow-color);
          box-shadow: 0px 2px 8px var(--small-outline-shadow-color);
  height: 0;
  padding-top: 100%;
}
.ImageUploadViewInner-wrapper[data-v-07e8b23e] {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  overflow: hidden;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}
.ImageUploadViewLabel[data-v-07e8b23e] {
  position: relative;
  display: block;
  z-index: 1;
  height: 24px;
  margin: 152px auto 0;
  padding: 0 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 99px;
  line-height: 24px;
  text-align: center;
  background: var(--image-upload-button-bg-color);
  color: var(--image-upload-button-color);
  font-weight: bold;
  font-size: 12px;
  -webkit-box-shadow: 0px 2px 8px var(--small-outline-shadow-color);
          box-shadow: 0px 2px 8px var(--small-outline-shadow-color);
}
.ImageUploadViewInput[data-v-07e8b23e] {
  display: none;
}
.ImageUploadViewPreview[data-v-07e8b23e] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.ImageUploadViewPreview img[data-v-07e8b23e] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ImageUploadViewPreview[data-v-07e8b23e]:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.04);
}
.progress-bar[data-v-07e8b23e] {
  position: absolute;
  bottom: 0;
  width: 100% !important;
  z-index: 3;
}
.progress-bar[data-v-07e8b23e] svg {
  width: 100% !important;
  display: block;
}
.ImageUploadViewCaption[data-v-07e8b23e] {
  margin-bottom: 12px;
}
.ImageUploadViewCaption b[data-v-07e8b23e] {
  display: inline-block;
  font: bold 18px/30px SF Pro Display, sans-serif;
  letter-spacing: -0.025em;
}
.ImageUploadViewCaption span[data-v-07e8b23e] {
  display: inline-block;
  float: right;
  font: 12px/30px SF Pro Display, sans-serif;
  letter-spacing: 0;
  color: #48555d;
  margin-top: 3px;
}
[data-v-07e8b23e] .CollectionImage {
  position: absolute;
  top: 0;
}
.u-ShowErrors .ImageUploadView.u-ErrorView .ImageUploadViewInner[data-v-07e8b23e] {
  border: 1px solid red;
}
