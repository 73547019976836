.Switcher.isDisabled {
  pointer-events: none;
  opacity: 0.5;
}
.Switcher-label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 20px;
  line-height: 24px;
  color: var(--switcher-text-color);
  padding-left: 48px;
}
.Switcher-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.Switcher-control {
  position: absolute;
  top: 2px;
  left: 0;
  width: 36px;
  height: 20px;
  border-radius: 99px;
  background-color: var(--switcher-bg-unchecked-color);
  -webkit-transition: 0.2s background-color;
  transition: 0.2s background-color;
}
.Switcher-control:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: var(--switcher-handle-color);
  border-radius: 99px;
  -webkit-transition: 0.2s -webkit-transform;
  transition: 0.2s -webkit-transform;
  transition: 0.2s transform;
  transition: 0.2s transform, 0.2s -webkit-transform;
}
.Switcher-input:checked ~ .Switcher-control {
  background-color: var(--switcher-bg-checked-color);
}
.Switcher-input:checked ~ .Switcher-control:after {
  -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
          transform: translateX(16px);
}
