.ListEditViewErrors .error {
  color: red;
}
.ListEditViewErrors {
  display: none;
}
.u-ShowErrors .ListEditViewErrors {
  display: block;
}
/*.ListEditView * {
   -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
}*/
html.dragging,
html.dragging * {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}
