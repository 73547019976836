html,
body,
#root {
  background: var(--bg-color);
  height: 100%;
}
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  min-width: 1024px;
  position: relative;
  padding: 0;
  margin: 0;
  font-family: SF Pro Display, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern";
  -ms-font-feature-settings: "kern" 1;
  font-feature-settings: "kern";
}
:root {
  color-scheme: light dark;
  --topnav-color: rgba(0, 0, 0, 0.4);
  --topnav-color-active: black;
  --bg-color: white;
  --add-collection-bg-color: #f0f0f0;
  --add-collection-cross-color: #c2c2c2;
  --popup-bg-color: rgba(0, 0, 0, 0.15);
  --popup-shadow-color: rgba(0, 0, 0, 0.05);
  --popup-form-bg-color: white;
  --popup-cancel-text-color: black;
  --popup-text-color: #141414;
  --popup-save-text-color: #141414;
  --popup-save-text-disabled-color: rgba(20, 20, 20, 0.3);
  --popup-delete-text-color: #FF3333;
  --image-upload-bg-color: #484848;
  --image-upload-button-color: #141414;
  --image-upload-button-bg-color: white;
  --small-outline-shadow-color: rgba(0, 0, 0, 0.1);
  --popup-form-title-color: black;
  --switcher-text-color: #333333;
  --switcher-bg-unchecked-color: #e6e6e6;
  --switcher-bg-checked-color: #333333;
  --switcher-handle-color: white;
  --input-border: #f0f0f0;
  --input-color: #141414;
  --input-placeholder-color: rgba(0, 0, 0, 0.2);
  --card-title-color: #141414;
  --card-words-color: #a7a7a7;
  --button-bg-color: rgba(169, 169, 169, 0.2);
  --button-bg-hover-color: rgba(255, 255, 255, 0.2);
  --button-icon-color: white;
  --button-icon-hover-color: #F0F0F0;
  --card-buttons-overlay-color: rgba(0, 0, 0, 0.56);
  --delete-card-bg-color: #F0F0F0;
  --export-notification-bg-color: #141414;
  --export-notification-content-color: white;
}
@media (prefers-color-scheme: dark) {
  :root {
    --topnav-color: rgba(255, 255, 255, 0.4);
    --topnav-color-active: white;
    --bg-color: black;
  }
}
ul,
li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
}
table {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}
table td {
  vertical-align: top;
  padding: 0;
}
table td.td--leftColumn {
  padding-right: 20px;
}
table td.td--rightColumn {
  padding-left: 20px;
}
.u-FlexColumns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -ms-flex-direction: row;
      flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.u-FlexColumns2 .u-FlexColumn {
  width: calc((100% - 40px) / 2);
}
.u-FlexColumns3 .u-FlexColumn {
  width: calc((100% - 80px) / 3);
}
.u-FlexColumns4 .u-FlexColumn {
  width: calc((100% - 120px) / 4);
}
.u-InputMarginView + .u-InputMarginView {
  margin-top: 7px;
}
.PopupShow-enter-active .PopupBgShowTransition {
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}
.PopupShow-leave-active .PopupBgShowTransition {
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.PopupShow-enter .PopupBgShowTransition,
.PopupShow-leave-to .PopupBgShowTransition {
  opacity: 0;
}
.PopupShow-enter-active .PopupBodyShowTransition {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}
.PopupShow-leave-active .PopupBodyShowTransition {
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.PopupShow-enter .PopupBodyShowTransition {
  opacity: 0;
  -webkit-transform: translateY(-40px);
      -ms-transform: translateY(-40px);
          transform: translateY(-40px);
}
.PopupShow-leave-to .PopupBodyShowTransition {
  opacity: 0;
}
.DefaultHover {
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  cursor: pointer;
}
.DefaultHover:hover {
  opacity: 0.7;
}
.DefaultHover:disabled {
  pointer-events: none;
}
.DefaultPage {
  padding: 20px 48px 100px;
  max-width: 1408px;
  min-width: 1024px;
  width: 100%;
  margin: 0 auto;
}
.TermGrid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
}
.TermGrid > * {
  width: 48.5%;
  word-break: break-word;
}
