.ExportIconButton[data-v-7d1f3b40] {
  position: relative;
  display: block;
  width: 56px;
  height: 56px;
  background: var(--button-bg-color);
  -webkit-backdrop-filter: blur(27.1828px);
          backdrop-filter: blur(27.1828px);
  border-radius: 50%;
  -webkit-transition: 0.2s background;
  transition: 0.2s background;
  cursor: pointer;
}
.ExportIconButton[data-v-7d1f3b40]:hover {
  background: var(--button-bg-hover-color);
}
.ExportIconButton:hover svg[data-v-7d1f3b40] {
  fill: var(--button-icon-hover-color);
}
.ExportIconButton svg[data-v-7d1f3b40] {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 26px;
  height: 26px;
  fill: var(--button-icon-color);
  -webkit-transition: 0.2s fill;
  transition: 0.2s fill;
}
