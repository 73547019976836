.TextareaAutoshrinkFont {
  position: relative;
  font-size: 18px;
  line-height: 30px;
}
.TextareaAutoshrinkFontLabel {
  display: block;
  font-family: SF Pro Display, sans-serif;
  font-weight: bold;
  letter-spacing: -0.025em;
}
.TextareaAutoshrinkFontInput {
  display: block;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  outline: none;
  border: 1px solid #d1d2d7;
  padding: 0;
  font: inherit;
  font-weight: normal;
  line-height: 1.4em;
  letter-spacing: 0;
  margin: 0;
  overflow: hidden;
}
textarea.TextareaAutoshrinkFontInput {
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  resize: none;
}
div.TextareaAutoshrinkFontInput {
  position: absolute;
  left: 0;
  top: 0;
  color: black;
  word-break: break-word;
  white-space: pre-wrap;
  pointer-events: none;
  opacity: 0.5;
  visibility: hidden;
}
.TextareaAutoshrinkFontLabel + .TextareaAutoshrinkFontInput {
  margin: 12px 0 0;
}
.TextareaAutoshrinkFont--cardTerm .TextareaAutoshrinkFontInput {
  text-align: center;
  border: none;
  font-weight: 600;
  border-radius: 8px;
}
.TextareaAutoshrinkFont--cardTerm.TextareaAutoshrinkFont--readonly .TextareaAutoshrinkFontInput {
  background: transparent;
  color: white;
}
.u-ShowErrors .TextareaView.u-ErrorView .TextareaViewInput {
  border-color: red;
}
