.InputViewLabel {
  display: block;
  font-family: SF Pro Display, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.025em;
}
.InputViewInput {
  display: block;
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #d1d2d7;
  background: transparent;
  padding: 6px 0 8px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
.InputViewLabel + .InputViewInput {
  margin: 12px 0 0;
}
.u-ShowErrors .InputView.u-ErrorView .InputViewInput {
  border-color: red;
}
.InputViewInput--transparentCaret {
  caret-color: transparent;
}
