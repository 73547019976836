.MediumEditorLabel {
  display: block;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.025em;
}
.MediumEditorInput {
  display: block;
  max-width: 100%;
  min-width: 100%;
  background: white;
  width: 100%;
  outline: none;
  border: 1px solid #d1d2d7;
  padding: 6px 7px 5px;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0;
  margin: 0;
  color: black;
}
.MediumEditorInput p {
  margin: 0;
}
.MediumEditorInput a {
  color: #00aada;
  text-decoration: none;
}
.MediumEditorInput mark {
  background: none;
  color: #F9A932;
}
.MediumEditorLabel + .MediumEditorInput {
  margin: 12px 0 0;
}
.MediumEditorIcon {
  fill: white;
  height: 16px;
}
.MediumEditorIcon-highlighter {
  width: 24px;
  height: 14px;
}
.MediumEditorIcon-bold {
  width: 10px;
  height: 14px;
}
.MediumEditorIcon-italic {
  width: 6px;
}
.MediumEditorIcon-link {
  width: 16px;
}
.MediumEditorIcon-ul {
  width: 16px;
}
.MediumEditorIcon-ol {
  width: 16px;
}
.medium-editor-button-active .MediumEditorIcon {
  fill: #00aada;
}
.medium-editor-toolbar li button {
  padding: 4px 11px 0;
}
.medium-editor-anchor-preview a {
  margin: 5px 5px 7px;
}
.medium-editor-placeholder-relative:after,
.medium-editor-placeholder:after {
  margin: 0 !important;
  padding: inherit !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  color: #9da1a3 !important;
}
.MediumEditor--subtitle .MediumEditorInput {
  padding: 17px 20px 17px;
}
.MediumEditor--form .MediumEditorInput {
  padding: 17px 18px 17px;
}
.MediumEditor--title .MediumEditorInput {
  font-size: 36px;
  line-height: 50px;
  letter-spacing: -0.05em;
  padding: 2px 18px 2px;
}
.MediumEditor--form_title .MediumEditorInput {
  font-size: 36px;
  line-height: 50px;
  letter-spacing: -0.055em;
  padding: 5px 18px 2px;
}
.MediumEditor--form_title_small .MediumEditorInput {
  font-weight: 300;
  font-size: 25px;
  line-height: 40px;
  letter-spacing: -0.05em;
  padding: 4px 17px 2px;
}
.u-ShowErrors .MediumEditor.u-ErrorView .MediumEditorInput {
  border-color: red;
}
.MediumEditor--footnotes .MediumEditorInput {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0;
  color: #8c959b;
  padding: 26px 18px;
}
